<template>
    <v-container>
      <NavButton class="mb-4" />
      <ManejoSolicitud 
        :requestId="requestId" 
        :hasActiveRequest="true" 
        :timer="timer" 
        :requestLoadable="requestToEdit"
        :requestsToModifyCount="0" 
        :isEditAction="true"
      />

  </v-container>

</template>


<script>
import CreateRequestComponent from '@/components/catalogoEnLinea/cart/CreateRequestComponent.vue';
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable.js';
import ActiveCartRequest from "@/components/catalogoEnLinea/cart/ActiveCartRequest.vue";
import ManejoSolicitud from "@/components/catalogoEnLinea/cart/ManejoSolicitud.vue";
import { transformDuration } from '@/utils/countdown';
import { Observable, Listener } from '@/utils/observable.js';
import NavButton from '@/components/utils/NavButton.vue';
import { mapState } from 'vuex';
import moment from "moment-timezone";
const validTimerStatus = ['ticking', 'expired', 'empty-request'];

export default {
  name: 'CartDrawerComponent',
  components: {
    CreateRequestComponent,
    ActiveCartRequest,
    ManejoSolicitud,
    NavButton
},
  data: () => ({
    requestId: null,
    requestToEdit: createLoadable(null),
    unsubscribe: null,
    unsubscribeDelete : null,
    timer: {
      status: 'initial',
      tick: null,
    },
  }),
  computed: {
    ...mapState(["serverTime"]),
    drawerInput: {
      get() {
        return this.cartDrawer;
      },
      set(value) {
        this.setCartDrawer(value);
      },
    },
    hasActiveRequest() {
      return Boolean(this.requestToEdit.data);
    },
    request() {
      return this.requestToEdit.data?.solicitud;
    },
    cartOffers() {
      return this.requestToEdit.data?.productosActualizados ?? [];
    },
    remainingTime() {
        if (!this.request || !this.serverTime) return null;
        const expiracion = moment(this.request.expiracion_carrito);
        const diff = expiracion.diff(this.serverTime);
        const duracion = moment.duration(diff);
        return duracion;
    },
  },
  methods: {
    async fetchRequest() {
      toggleLoadable(this.requestToEdit);
      const { data } = await this.services.PurchaseRequest.getRequestById(this.requestId);
      setLoadableResponse(this.requestToEdit, data);
    },
    handleOverlayClick() {
      this.setCartDrawer(false);
    },
    setTimerStatus(status) {
      if (!validTimerStatus.includes(status)) return;
      this.timer.status = status;
    },
    setTimerTick(tick) {
      this.timer.tick = tick;
    },
    tick(duration) {
      if (this.timer.status !== 'ticking') this.setTimerStatus('ticking');

      this.setTimerTick(duration);
    },
    handleExpired() {
      this.setTimerTick(null);
      this.setTimerStatus('expired');
    },
    handleTimerStart() {
      this.setTimerStatus('ticking');
    },
    handleOfferChange(_, offerUpdate) {
      const update = offerUpdate.objetoActualizado;
      const disponibilidad = offerUpdate.oferta_actualizada.disponibilidad;
      const cartItems = this.requestToEdit.data?.productosActualizados ?? [];

      const itemToUpdateIndex = cartItems.findIndex((item) => item.id === update.id);

      if (itemToUpdateIndex === -1) {
        cartItems.push(insertion);
      } else {
        cartItems[itemToUpdateIndex].cmopc.cmsc.altura = update.altura;
        cartItems[itemToUpdateIndex].cmopc.cmsc.base = update.base;
        cartItems[itemToUpdateIndex].cantidad = update.cantidad;
        cartItems[itemToUpdateIndex].subtotal = update.subtotal;
        cartItems[itemToUpdateIndex].cmopc.disponibilidad = disponibilidad;
      }
    },
    handleOfferDelete(_, offerToRemove){
      const offers = this.requestToEdit.data?.productosActualizados ?? [];
            const index = offers.findIndex((offer) => offer.id === offerToRemove.id);

            if (index === -1) return;

            offers.splice(index, 1);
    }
  },
  watch: {
    remainingTime(value) {
      // No hay carrito activo
      if (value === null) {
        if (this.timer.status !== 'empty-request') this.setTimerStatus('empty-request');

        return;
      }

      // El tiempo del carrito aun esta vigente
      if (value > 0) return this.tick(value);

      // El tiempo del carrito ha expirado
      this.handleExpired();
    },
  },
  created() {
    this.requestId = this.$route.params.id;
    this.fetchRequest();
  },
  mounted() {
    const listener = new Listener('ProductOffers').setHandler(this.handleOfferChange);
    this.unsubscribe = Observable.getInstance().subscribe('offert-change-on-edit-request', listener);

    const listenerDelete = new Listener('ProductOffers').setHandler(this.handleOfferDelete);
    this.unsubscribeDelete = Observable.getInstance().subscribe('offert-delete-change', listenerDelete);
  },
  beforeDestroy() {
    // Countdown.getInstance().clear();
    this.unsubscribe?.();
    this.unsubscribeDelete?.();
  },
}
</script>
